const Loader = () => {
    return (
        <>
            <div className="relative -top-4 mb-2 w-full">
                <div className="flex items-center justify-center">
                    <span className="w-5 opacity-100">
                        <svg
                            className="text-white m-auto h-auto animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                            ></circle>
                            <path
                                className="fill-primary-500"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    </span>
                    <span className="pl-2 text-sm text-interface-600">
                        Loading, please wait
                    </span>
                </div>
            </div>
        </>
    )
}
export default Loader
