import { Menu } from '@headlessui/react'
import map from 'lodash/map'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useFetcher from '@/redux/hooks/useFetcher'

import { useState, useEffect } from 'react'

import Count from '@/components/Count'
import Notification from '@/components/Notification'
import OutsideClickHandler from 'react-outside-click-handler'

import styles from '@/styles/Navigation.module.css'

const Navigation = (props) => {
    const { asPath, pathname } = useRouter()
    const { setAlertMessage } = useFetcher()

    const [isNotificationActivity, setIsNotificationActivity] = useState()
    const [dropDown, setdropDown] = useState(false)

    if (
        pathname &&
        pathname !== '/my-trip' &&
        pathname !== '/book-flight/confirm-itinerary' &&
        pathname !== '/my-trip/[id]/payment' &&
        pathname !== '/my-trip/[id]/review-quotes'
    ) {
        setAlertMessage(null)
    }

    const menuMore = () => {
        if (!dropDown) {
            setdropDown(true)
        } else {
            setdropDown(false)
        }
    }
    const notificationClose = () => {
        setIsNotificationActivity(false)
        document.body.classList.remove('overflow-hidden')
    }
    useEffect(() => notificationClose, [asPath, pathname])

    return (
        <div className={styles.navigation}>
            {props.mainMenu && (
                <ul key="main-menue" className="ml-auto mr-6">
                    {map(props.items.slice(0, 3), (item, index) => (
                        <li
                            key={`${index}-${item.route}`}
                            className={`${
                                asPath === item.route ? styles.active : ''
                            } ${item.disabled ? ' tooltip' : ''}`}
                        >
                            <Link
                                key={`${item.route}-${index}`}
                                href={item.route}
                            >
                                <a
                                    className={`${
                                        asPath === item.route
                                            ? styles.active
                                            : '' ||
                                              item?.childRoutes?.find(
                                                  (element) => {
                                                      return (
                                                          element.route ===
                                                          pathname
                                                      )
                                                  }
                                              )
                                            ? styles.active
                                            : ''
                                    }  ${item.disabled ? styles.disabled : ''}`}
                                >
                                    {item.label}
                                </a>
                            </Link>
                            <span className="tooltip-content tooltip-content-bottom top-[-2px] left-[-100px] max-w-[350px]">
                                Your sub account doesn&apos;t allow access to
                                this
                            </span>
                        </li>
                    ))}
                    <li key="menu">
                        <Menu
                            as="div"
                            className="navigation-link relative inline-block"
                        >
                            <div className={`relative`}>
                                <Menu.Button className="dropdown-button relative top-[-1px]">
                                    MORE
                                    <i className="icon-caret-down"></i>
                                </Menu.Button>
                                <div
                                    className={` absolute top-0 h-full w-full cursor-pointer`}
                                    onClick={menuMore}
                                ></div>
                            </div>
                            {dropDown && (
                                <OutsideClickHandler
                                    onOutsideClick={() => setdropDown(false)}
                                >
                                    <div>
                                        <div
                                            className={`dropdown-menu -right[17px] top-[33px] -right-[20px] w-[180px]`}
                                        >
                                            {map(
                                                props.items.slice(
                                                    3,
                                                    props.items.length
                                                ),
                                                (item, index) => (
                                                    <Menu.Item
                                                        key={`${index}-${item.route}`}
                                                    >
                                                        <>
                                                            {item?.route && (
                                                                <Link
                                                                    key={index}
                                                                    href={
                                                                        item.route
                                                                    }
                                                                >
                                                                    <a
                                                                        onClick={
                                                                            menuMore
                                                                        }
                                                                        className={`${
                                                                            pathname ===
                                                                            item.route
                                                                                ? styles.active
                                                                                : ''
                                                                        } ${
                                                                            item.disabled
                                                                                ? styles.disabled
                                                                                : ''
                                                                        }
                                                                `}
                                                                    >
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </a>
                                                                </Link>
                                                            )}
                                                            {item?.click && (
                                                                <span
                                                                    className={`click-menu block w-full cursor-pointer ${
                                                                        item.click
                                                                            ? styles.active
                                                                            : ''
                                                                    } ${
                                                                        item.disabled
                                                                            ? styles.disabled
                                                                            : ''
                                                                    }} `}
                                                                    onClick={
                                                                        props.navClick
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </span>
                                                            )}
                                                        </>
                                                    </Menu.Item>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </OutsideClickHandler>
                            )}
                        </Menu>
                    </li>
                    <li key="count">
                        {/* Notification Icon */}
                        <Count
                            uri="notification/count"
                            setIsModalOpen={setIsNotificationActivity}
                        />
                    </li>
                    <div className="fixed top-16 w-56 text-right"></div>
                </ul>
            )}
            {!props.mainMenu && (
                <ul key="not-main-menue" className="ml-auto">
                    {map(props.items, (item, index) => (
                        <li
                            key={`${index}-${item.route}`}
                            className={
                                asPath === item.route ? styles.active : ''
                            }
                        >
                            <Link href={item.route}>
                                <a
                                    className={
                                        asPath === item.route
                                            ? styles.active
                                            : ''
                                    }
                                >
                                    {item.label}
                                </a>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
            {isNotificationActivity && (
                <OutsideClickHandler onOutsideClick={notificationClose}>
                    <Notification onClose={notificationClose} />
                </OutsideClickHandler>
            )}
        </div>
    )
}

export default Navigation
