import { useMemo } from 'react'
import { useFormikContext } from 'formik'
import styles from '@/styles/Checkbox.module.css'

const LegListItem = ({ flight }) => {
    const { getFieldMeta, handleChange } = useFormikContext() || {}
    const { value } = getFieldMeta('flightIds')

    const isChecked = useMemo(
        () => value.includes(flight.id.toString()),
        [value, flight]
    )

    return (
        <li>
            <span className="flex w-1/2 justify-start">
                {flight?.originCode} -&gt; {flight?.destinationCode}
            </span>
            <span className="flex w-1/2 justify-end">
                <div className="flex">
                    <label htmlFor={flight?.id} className={styles.checkBox}>
                        <input
                            id={flight?.id}
                            name="flightIds"
                            type="checkbox"
                            value={flight.id.toString()}
                            checked={isChecked}
                            onChange={handleChange}
                        />
                        <span className={styles.checkmark}>
                            <i className="icon-check"></i>
                        </span>
                    </label>
                </div>
            </span>
        </li>
    )
}

export default LegListItem
