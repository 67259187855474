import Link from 'next/link'
import Image from 'next/image'

const Footer = () => {
    return (
        <div className="bg-common-white py-6 print:hidden ipad:px-[30px]">
            <div className="inner">
                <div className="m-auto flex w-full max-w-[1024px]">
                    <div className="flex w-full items-center">
                        <div className="w-full">
                            <p className="mb-0 text-interface-600">
                                Copyright {new Date().getFullYear()}{' '}
                                {process.env.NEXT_PUBLIC_APP_NAME}. All Rights
                                Reserved.
                            </p>
                        </div>
                        <div>
                            <ul className="flex items-center text-lg">
                                <li className="mr-7 inline">
                                    <Link href="https://www.facebook.com/v2jets/">
                                        <a
                                            target="_blank"
                                            className="text-interface-400"
                                        >
                                            <i className="icon-facebook"></i>
                                        </a>
                                    </Link>
                                </li>
                                <li className="mr-7 inline">
                                    <Link href="https://www.instagram.com/v2jets/">
                                        <a
                                            target="_blank"
                                            className="text-interface-400"
                                        >
                                            <i className="icon-instagram"></i>
                                        </a>
                                    </Link>
                                </li>
                                <li className="mt-[2px] inline">
                                    <Link href="https://www.linkedin.com/company/v2jets/mycompany/">
                                        <a target="_blank">
                                            <Image
                                                src="/linkedin.png"
                                                alt="linkedin-logo"
                                                layout="fixed"
                                                width={18}
                                                height={18}
                                            />
                                        </a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer
