import Image from 'next/image'

const NoRecordFound = ({
    title,
    imgWidth,
    imgHeight,
    altText,
    image,
    description,
}) => {
    return (
        <div className="flex h-full w-full flex-col items-center justify-center">
            <div
                className={`mx-auto mb-6 ${imgWidth ? imgWidth : 'w-[180px]'} ${
                    imgHeight ? imgHeight : 'w-[78px]'
                }`}
            >
                <Image
                    src={image ? image : '/no-record-found/not-found.svg'}
                    alt={altText ? altText : 'no-record-found'}
                    layout="responsive"
                    width={145}
                    height={78}
                />
            </div>
            <div className="mx-auto w-[360px] mobile:w-[300px]">
                <h4 className="mb-2 text-center font-acumin-bold text-lg text-common-black">
                    {title ? title : 'No data available.'}
                </h4>
                <p className="mb-0 text-center text-interface-600">
                    {description
                        ? description
                        : 'There is no data available currently.'}
                </p>
            </div>
        </div>
    )
}

export default NoRecordFound
