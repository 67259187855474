import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    item: null,
    alertMessage: {},
    resetList: false,
}

export const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
        setItem: (state, { payload }) => {
            state.item = payload
        },
        setAlert: (state, { payload }) => {
            state.alertMessage = payload
        },
        setListReset: (state, { payload }) => {
            state.resetList = payload
        },
    },
})

export const { setItem, setAlert, setListReset } = sharedSlice.actions

export default sharedSlice.reducer
