import * as Yup from 'yup'
import { subscribe } from './messages'

export default Yup.object().shape({
    bookingId: Yup.string().required(subscribe.bookingId.required),
    clientName: Yup.string()
        .required(subscribe.lastName.required)
        .min(1, subscribe.lastName.min)
        .max(100, subscribe.lastName.max),
})
