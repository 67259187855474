/* eslint-disable max-lines */
const login = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    password: {
        required: 'Password field is required.',
        min: 'Password need to have at least 8 characters.',
        matches:
            'Password must contain at least one uppercase letter, one lowercase letter, one number.',
    },
}
const forgot = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    mobileNumber: {
        required: 'Mobile number field is required.',
        invalid: 'Please enter a valid mobile number.',
    },
}
const resetPassword = {
    password: {
        required: 'New password field is required.',
        min: 'New password field must be between 8 to 50 characters.',
        max: 'New password field must be between 8 to 50 characters.',
        mismatch: 'New password and confirm password fields do not match.',
        same: 'New password cannot be same as current password.',
    },
    confirmPassword: {
        min: 'Confirm password field must be between 8 to 50 characters.',
        max: 'Confirm password field must be between 8 to 50 characters.',
        required: 'Confirm password is required.',
    },
}
const resetPasswordSubscriber = {
    password: {
        required: 'New password field is required.',
        min: 'New password field must be between 8 to 50 characters.',
        max: 'New password field must be between 8 to 50 characters.',
        mismatch: 'New password and confirm password fields do not match.',
        same: 'New password cannot be same as current password.',
    },
    confirmPassword: {
        min: 'Confirm password field must be between 8 to 50 characters.',
        max: 'Confirm password field must be between 8 to 50 characters.',
        required: 'Confirm password is required.',
    },
    firstName: {
        required: 'First name field is required.',
    },

    lastName: {
        required: 'Last name field is required.',
    },
    mobileNumber: {
        required: 'Mobile number field is required.',
        matches: 'Please enter a valid mobile number e.g +1 (925) 292-6668',
    },
}
const changePassword = {
    oldPassword: {
        required: 'Old password is required.',
        min: 'Old password field must be between 8 to 50 characters.',
        max: 'Old password field must be between 8 to 50 characters.',
    },
    newPassword: {
        required: 'New password field is required.',
        min: 'New password field must be between 8 to 50 characters.',
        max: 'New password field must be between 8 to 50 characters.',
        mismatch: 'New password and confirm password fields do not match.',
        same: 'New password cannot be same as current password.',
    },
    confirmPassword: {
        min: 'Confirm password field must be between 8 to 50 characters.',
        max: 'Confirm password field must be between 8 to 50 characters.',
        required: 'Confirm password is required.',
    },
}
const register = {
    firstName: {
        required: 'First name field is required.',
    },

    lastName: {
        required: 'Last name field is required.',
    },
    companyName: {
        required: 'Company name field is required.',
        min: 'Company name needs to have at least 2 characters.',
        max: 'Company name should not be more than 255 characters.',
    },

    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    mobileNumber: {
        required: 'Mobile number field is required.',
        matches: 'Please enter a valid mobile number e.g +1 (925) 292-6668',
    },
    workMobileNumber: {
        required: 'Work phone number field is required.',
        matches: 'Please enter a valid work phone number e.g +1 (925) 292-6668',
    },
    streetAddress1: {
        required: 'Street address 1 field is required.',
        min: 'Street address 1 needs to have at least 2 characters.',
        max: 'Street address 1 should not be more than 255 characters.',
    },
    streetAddress2: {
        required: 'Street address 2 field is required.',
        min: 'Street address 2 needs to have at least 2 characters.',
        max: 'Street address 2 should not be more than 255 characters.',
    },
    city: {
        required: 'City field is required.',
        min: 'City needs to have at least 2 characters.',
        max: 'City should not be more than 100 characters.',
    },
    state: {
        required: 'State field is required.',
        min: 'State needs to have at least 2 characters.',
        max: 'State should not be more than 100 characters.',
    },
    zipCode: {
        required: 'Zip code field is required.',
        min: 'Zip code needs to have at least 2 characters.',
        max: 'Zip code should not be more than 255 characters.',
    },
    password: {
        required: 'Password field is required.',
        min: 'Password field must be between 8 to 50 characters.',
        max: 'Password field must be between 8 to 50 characters.',
        match: 'Password and confirm password must be same.',
    },
}
const verify = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    verificationCode: {
        required: 'verification code field is required.',
        min: 'verification code need to have 5characters.',
        matches:
            'Password must contain at least one uppercase letter, one lowercase letter, one number.',
    },
}
const cateringMenu = {
    title: {
        required: 'Title field is required.',
    },
    parentId: {
        required: 'Menu category field is required.',
    },
    description: {
        required: 'Short description field is required.',
    },
}
const configurations = {
    taxPercentage: {
        required: 'FET tax percentage field is required.',
        min: 'FET tax percentage min value is 0.',
        max: 'FET tax percentage max value is 100.',
    },
    segmentFeeTax: {
        required: 'Segment fee tax field is required.',
        min: 'Segment fee tax min value is 0.',
        max: 'Segment fee tax max value is 10000.',
    },
    processingFeePercentage: {
        required: 'Credit card processing fee field is required.',
        min: 'Segment fee tax min value is 0.',
        max: 'Segment fee tax max value is 100.',
    },
    contactEmail: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    contactNumber: {
        required: 'Contact number field is required.',
    },
    coverPhotoURLs: {
        required: 'Cover photos field is required',
    },
}
const newsUpdate = {
    title: {
        required: 'Title field is required.',
    },
    coverPhoto: {
        required: 'Cover photo field is required.',
    },
    excerpt: {
        required: 'Excerpt field is required.',
    },
    content: {
        required: 'Content field is required.',
    },
}
const content = {
    content: {
        required: 'Content field is required.',
    },
}
const aircraftClass = {
    name: {
        required: 'Name field is required.',
    },
    photoURLs: {
        required: 'Aircraft class photos field is required.',
    },
}
const aircrafts = {
    name: {
        required: 'Name field is required.',
    },
    aircraftClassId: {
        required: 'Aircraft class field is required.',
    },
    subtitle: {
        required: 'Sub heading field is required.',
    },
    passengerCapacity: {
        required: 'Passengers field is required.',
    },
    maxSpeed: {
        required: 'Max speed field is required.',
    },
    range: {
        required: 'Range field is required.',
    },
    dimensions: {
        required: 'Dimensions field is required.',
    },
    description: {
        required: 'Description is required',
    },
    photoURLs: {
        required: 'Aircraft photos field is required.',
        max: 'You can upload maximum 3 photos.',
    },
}
const announcements = {
    message: {
        required: 'Message is required',
    },
    userIds: {
        required: 'Select at least on user.',
    },
}
const frequentFlier = {
    firstName: {
        required: 'First name field is required.',
    },

    lastName: {
        required: 'Last name field is required.',
    },
    dateOfBirth: {
        required: 'Date of birth field is required',
    },
    weight: {
        required: 'Estimated weight field is required.',
        min: 'Estimated weight need to have at least 1 characters.',
        max: 'Estimated weight should not be more than 5 characters.',
    },
    passportNumber: {
        required: 'Passport number field is required.',
    },
}
const paymentMethod = {
    cardHolderName: {
        required: 'Cardholder name field is required.',
        min: 'Cardholder name need to have at least 2 characters.',
        max: 'Cardholder name should not be more than 100 characters.',
    },

    cardNumber: {
        required: 'Card number field is required.',
        min: 'Card number need to have at least 13 characters.',
        max: 'Card number should not be more than 16 characters.',
        typeError: 'Card number should be number.',
    },

    expirationMonth: {
        required: 'Expire month field is required.',
        min: 'Expire month need to have at least 2 characters.',
        max: 'Expire month should not be more than 2 characters.',
    },

    expirationYear: {
        required: 'Expire year field is required.',
        min: 'Expire year need to have at least 2 characters.',
        max: 'Expire year should not be more than 2 characters.',
    },

    cvc: {
        required: 'CVC field is required.',
        min: 'CVC need to have at least 3 characters.',
        max: 'CVC should not be more than 4 characters.',
        positive: 'CVC should  be positive number.',
    },

    billingAddress: {
        required: 'Billing address field is required.',
        min: 'Billing address needs to have at least 2 characters.',
        max: 'Billing address should not be more than 250 characters.',
    },

    notes: {
        required: 'Nickname field is required.',
        min: 'Nickname need to have at least 2 characters.',
        max: 'Nickname should not be more than 250 characters.',
    },

    streetAddress1: {
        min: 'Street address #1 needs to have at least 2 characters.',
        max: 'Street address #1 should not be more than 100 characters.',
    },

    streetAddress2: {
        min: 'Street address #2 needs to have at least 2 characters.',
        max: 'Street address #2 should not be more than 100 characters.',
    },

    city: {
        min: 'City needs to have at least 2 characters.',
        max: 'City should not be more than 100 characters.',
    },

    state: {
        min: 'State needs to have at least 2 characters.',
        max: 'State should not be more than 100 characters.',
    },

    zipCode: {
        min: 'Zip code needs to have at least 2 characters.',
        max: 'Zip code should not be more than 20 characters.',
    },

    isAuthorized: {
        required: 'You must agree to terms and conditions.',
    },
}
const flightTracking = {
    flightTrackingCode: {
        required: 'Flight tracking field is required.',
    },

    tailNumber: {
        required: 'Tail number field is required.',
    },
    aircraftOperatorPrice: {
        required: 'Aircraft operator price field is required',
    },
}
const addPassenger = {
    frequentFlierId: {
        required: 'Frequent flier field is required.',
    },
    firstName: {
        required: 'First name field is required.',
        min: 'First name needs to have at least 2 characters.',
        max: 'First name should not be more than 100 characters.',
    },

    lastName: {
        required: 'Last name field is required.',
        min: 'Last name needs to have at least 2 characters.',
        max: 'Last name should not be more than 100 characters.',
    },
    dateOfBirth: {
        required: 'Date of birth field is required.',
    },
    weight: {
        required: 'Weight field is required.',
        min: 'Weight needs to have at least 2 characters.',
        max: 'Weight should not be more than 10 characters.',
    },
    passportNumber: {
        required: 'Passport number field is required.',
    },
    documents: {
        required: 'Documents field is required.',
    },
    email: {
        invalid: 'Please enter a valid email address.',
    },
    mobileNumber: {
        matches: 'Please enter a valid mobile number e.g +1 (925) 292-6668',
    },
    address: {
        min: 'Address needs to have at least 2 characters.',
        max: 'Address should not be more than 255 characters.',
    },
}
const selectFBO = {}

const contactUs = {
    reason: {
        required: 'Reason field is required.',
    },
    message: {
        required: 'Message field is required.',
        min: 'Message needs to have at least 2 characters.',
        max: 'Message should not be more than 500 characters.',
    },
}
const reviewQuote = {
    aircraftId: {
        required: 'Please select an aircraft option to continue.',
    },
}

const associateClient = {
    firstName: {
        required: 'First name field is required.',
        min: 'First name must be at least 2 characters.',
        max: 'First name must be at most 100 characters.',
    },

    lastName: {
        required: 'Last name field is required.',
        min: 'Last name must be at least 2 characters.',
        max: 'Last name must be at most 100 characters.',
    },

    permission: {
        required: 'Permission field is required.',
    },

    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    mobileNumber: {
        matches: 'Please enter a valid mobile number e.g +1 (925) 292-6668',
    },
}
const subscribe = {
    flightIds: {
        required: 'Please select atleast one leg to subscribe.',
    },
    bookingId: {
        required: 'Trip number field is required.',
    },
    users: {
        required: 'Please select atleast 1 email reciept.',
    },
    lastName: {
        required: 'Last name field is required.',
        min: 'Last name must be at least 2 characters.',
        max: 'Last name must be at most 100 characters.',
    },
}

export {
    login,
    forgot,
    resetPassword,
    resetPasswordSubscriber,
    changePassword,
    register,
    verify,
    cateringMenu,
    configurations,
    newsUpdate,
    content,
    aircraftClass,
    announcements,
    frequentFlier,
    paymentMethod,
    aircrafts,
    flightTracking,
    addPassenger,
    selectFBO,
    contactUs,
    reviewQuote,
    associateClient,
    subscribe,
}
