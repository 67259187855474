import Link from 'next/link'
import { useFormikContext } from 'formik'

import styles from '../../styles/Button.module.css'

const Button = (props) => {
    const { isSubmitting: loading } = useFormikContext() || {}

    const disabled = props.disabled || loading

    return (
        <button
            className={`btn ${styles.btn} ${styles[props.size]} 
    ${props.modification ? props.modification : 'w-auto px-5'} ${
                styles[props.variant]
            } ${styles[props.size]} ${
                disabled || (loading && props.type !== 'submit')
                    ? styles.disabled
                    : ''
            } ${
                (props.loading || loading) &&
                (props.type === 'submit' || props.type === 'button')
                    ? styles.loading
                    : ''
            }`}
            disabled={disabled}
            type={props.type ? props.type : 'submit'}
            onClick={props.click}
            id={props.id}
        >
            <div
                className={`icon-position flex items-center justify-center ${
                    styles.iconPosition
                } ${
                    styles[props.textModification]
                        ? styles[props.textModification]
                        : ''
                } ${styles[props.position]}`}
            >
                {props.icon && (
                    <i
                        className={`${`icon-` + props.icon} ${props.iconStyle}`}
                    ></i>
                )}
                <span
                    className={`font-themeFont ${styles.labelText} ${
                        props.labelColor
                    } ${props.uppercase ? 'uppercase' : ''}`}
                >
                    {props.text}
                </span>
            </div>
            <span className={styles.spinner}>
                <svg
                    className="text-white m-auto h-auto w-full animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                    ></circle>
                    <path
                        className="fill-common-white"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </span>
            {props.link && (
                <>
                    <Link href={props.link}>
                        <a className="absolute top-0 left-0 right-0 z-10 h-full w-full"></a>
                    </Link>
                </>
            )}
        </button>
    )
}

export default Button
