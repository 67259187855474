const SeparatorWithText = (props) => {
    return (
        <div className="separator-with-text">
            <span
                className={
                    props.bgColor
                        ? props.bgColor
                        : 'bg-common-white text-xs text-xs font-semibold uppercase text-gray-600'
                }
            >
                {props.label}
            </span>
        </div>
    )
}

export default SeparatorWithText
