import keys from 'lodash/keys'
import { Formik, Form } from 'formik'
import { useMemo, useState, useCallback, useEffect } from 'react'

import Alert from '@/components/base/Alert'
import Button from '@/components/base/Button'
import TextBox from '@/components/base/TextBox'

import { useSession } from '@/contexts/Session'

import axios from '@/data/axios'
import modelValidation from '@/validations/change-password'

const ChangePasswordModal = ({ setChangePassword }) => {
    const { user } = useSession()

    const [successMessage, setSuccessMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const initialState = useMemo(
        () => ({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        }),
        []
    )

    const handleSubmit = useCallback(
        async (
            { oldPassword, newPassword, confirmPassword },
            { setErrors }
        ) => {
            setLoading(true)
            try {
                const { data } = await axios.put('user/password', {
                    oldPassword,
                    newPassword,
                    confirmPassword,
                })
                if (data) {
                    const { message } = data
                    setSuccessMessage(message)
                }
            } catch ({ response }) {
                setLoading(false)
                let message = response.data.message
                let key = keys(initialState)[0]
                if (response.data.errors) {
                    const entries = Object.entries(response.data.errors)
                    if (entries.length) {
                        key = entries[0][0]
                        message = entries[0][1]
                    }
                }
                setErrors({
                    [key]: message,
                })
            }
        },
        [initialState, setSuccessMessage]
    )

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(null)
                setChangePassword(false)
                setLoading(false)
            }, 3000)
            return () => clearTimeout(timer)
        }
    }, [successMessage, setSuccessMessage, setChangePassword, setLoading])

    return (
        <>
            <div>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialState}
                    validationSchema={modelValidation}
                    validateOnBlur={false}
                >
                    {({ errors, touched }) => {
                        const firstKey = keys(errors)[0]
                        const showError =
                            !!errors[firstKey] && !!touched[firstKey]

                        return (
                            <Form noValidate>
                                {user.forceChangePassword && (
                                    <div>
                                        <h5 className="mb-4 text-sm">
                                            V2 Jets set your password manually
                                            and now it’s time to change it
                                            manually.
                                        </h5>
                                    </div>
                                )}
                                <div>
                                    <TextBox
                                        id="oldPassword"
                                        name="oldPassword"
                                        label="Old Password"
                                        type="password"
                                        placeholder="Enter your old password"
                                    />
                                </div>
                                <div>
                                    <TextBox
                                        id="newPassword"
                                        name="newPassword"
                                        label="New Password"
                                        type="password"
                                        placeholder="Create new password"
                                    />
                                </div>
                                <div>
                                    <TextBox
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        placeholder="Re-enter new password"
                                        noSpace
                                    />
                                </div>
                                <div className="mt-6">
                                    {showError && (
                                        <Alert
                                            variant="danger"
                                            message={errors[firstKey]}
                                            icon="close"
                                            iconStyle="text-danger-500 pr-2 text-xs"
                                        ></Alert>
                                    )}

                                    {successMessage && (
                                        <Alert
                                            variant="success"
                                            message={successMessage}
                                            icon="check"
                                            iconStyle="text-success-500 pr-2 text-xs"
                                        ></Alert>
                                    )}
                                </div>
                                <div className="mt-6 grid grid-cols-2 gap-4">
                                    <div>
                                        <Button
                                            text="Cancel"
                                            type="reset"
                                            variant="outline_interface"
                                            modification="w-full"
                                            size="other"
                                            click={() =>
                                                setChangePassword(false)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            id="change_password_submit"
                                            modification="w-full shadow-md"
                                            variant="primary"
                                            text="Update"
                                            type="submit"
                                            size="other"
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </>
    )
}

export default ChangePasswordModal
