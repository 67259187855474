import React, { useCallback } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component'

import map from 'lodash/map'

import styles from '@/styles/Notification.module.css'

import Loader from '@/components/base/Loader'
import Loading from '@/components/base/Loading'
import NoRecordFound from '@/components/base/NoRecordFound'
import Booking from '@/components/notification/Booking'
import Announcement from '@/components/notification/Announcement'

import axios from '@/data/axios'
import usePagination from '@/data/usePagination'

import { useSession } from '@/contexts/Session'

const notificationTypeComponent = {
    booking: Booking,
    announcement: Announcement,
}

const Notifications = ({ onClose }) => {
    const { primaryClient } = useSession()

    const selectedPrimaryClient = primaryClient.clientDetails
        ? JSON.parse(JSON.stringify(primaryClient.clientDetails))
        : null

    const url = '/notification'
    const pageSize = 12
    const {
        data,
        size,
        mutate,
        setSize,
        isReachingEnd,
        isLoadingMore,
        isLoadingInitialData,
    } = usePagination(
        url,
        pageSize,
        {
            clientId: selectedPrimaryClient?.clientId,
        },
        true
    )

    const remove = useCallback(
        async (id = null) => {
            let uri = 'notification/clear-all'
            if (id) {
                uri = `notification/${id}`
            }
            const {
                data: { deleted },
            } = await axios.delete(uri)

            if (deleted) {
                mutate()
            }
        },
        [mutate]
    )

    const read = useCallback(async (id) => {
        await axios.put(`notification`, {
            notificationIds: [id],
        })
    }, [])

    const getComponent = (item) => {
        const Component = notificationTypeComponent[item.module]
        if (Component) {
            return (
                <Component
                    key={item.id}
                    notificationDetail={item}
                    remove={remove}
                    read={read}
                    onClose={onClose}
                />
            )
        }
    }

    return (
        <div>
            <div className={`${styles.logsBlock}`}>
                <h2 className={styles.title}>Notifications</h2>
                <span
                    className="absolute right-4 top-6 cursor-pointer text-interface-400"
                    onClick={onClose}
                >
                    <i className="icon-close"></i>
                </span>
                <div className="notifications text-sm">
                    <div
                        id="notificationsScrollableDiv"
                        className="scrollable notification-scroll h-[calc(100vh-130px)] overflow-auto"
                    >
                        <ul className={styles.items}>
                            <InfiniteScroll
                                next={() => {
                                    setSize(size + 1)
                                }}
                                hasMore={!isReachingEnd}
                                dataLength={data?.length || 0}
                                scrollableTarget="notificationsScrollableDiv"
                            >
                                {map(data, (item) => getComponent(item))}
                            </InfiniteScroll>
                        </ul>
                        {!!data.length && isLoadingMore && (
                            <div className="mt-8">
                                <Loader />
                            </div>
                        )}
                        {!data.length && !isLoadingInitialData && (
                            <div className="flex min-h-[400px] items-center justify-center">
                                <NoRecordFound />
                            </div>
                        )}
                        {!data.length && isLoadingInitialData && (
                            <div className="mt-6">
                                <Loading
                                    loadingHeight="h-[calc(100vh-110px)]"
                                    loadingWidth="100%"
                                />
                            </div>
                        )}
                    </div>
                    {!!data?.length && (
                        <span
                            onClick={() => remove()}
                            className="absolute right-[20px] bottom-[20px] cursor-pointer text-primary-500 underline"
                        >
                            Clear all
                        </span>
                    )}
                </div>
            </div>
            {/* Backdrop */}
            <div className={`${styles.backDrop}`}></div>
        </div>
    )
}

export default Notifications
