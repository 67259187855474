import { useRouter } from 'next/router'

import Preloader from '@/components/base/Preloader'

import { useSession } from '@/contexts/Session'

const Auth = ({ children }) => {
    const { push, asPath, pathname } = useRouter()
    const { isAuthenticated, setNextURL, scopes } = useSession({
        required: true,
        onUnauthenticated() {
            if (isAuthenticated && !asPath.startsWith('_next')) {
                setNextURL('path', asPath, {
                    path: '/',
                })
            }
            if (asPath !== '/') {
                push('/')
            }
        },
        onAuthenticated() {
            const hasScope =
                scopes.includes(asPath.slice(1).replaceAll('/', '-')) ||
                scopes.includes(pathname.slice(1).replaceAll('/', '-'))

            if (scopes.length && !hasScope && asPath !== '/home') {
                push('/home')
            }
        },
    })

    if (isAuthenticated) {
        return children
    }

    return <Preloader></Preloader>
}

export default Auth
