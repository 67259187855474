import { useState, useEffect, useCallback } from 'react'

import styles from '@/styles/Navigation.module.css'

import axios from '@/data/axios'

import { useSession } from '@/contexts/Session'

const Count = ({ uri, setIsModalOpen }) => {
    const [count, setCount] = useState(null)
    const [toggle, setToggle] = useState(false)

    const { primaryClient } = useSession()

    const selectedPrimaryClient = primaryClient.clientDetails
        ? JSON.parse(JSON.stringify(primaryClient.clientDetails))
        : null

    /* get unread notifications count */
    const getCount = useCallback(async () => {
        try {
            const { data } = await axios.get(uri, {
                params: {
                    clientId: selectedPrimaryClient?.clientId,
                },
            })
            if (data?.data) {
                setCount(data.data > 9 ? '9+' : data.data)
            } else {
                setCount(0)
            }
        } catch ({ response }) {
            if (response?.data?.message) setCount(0)
        }
    }, [selectedPrimaryClient?.clientId, uri])

    // /* mark read all notifications */
    // const markRead = async () => {
    //     try {
    //         const { data } = await axios.put('/notification')
    //         if (data) {
    //             setCount(0)
    //         }
    //     } catch ({ response }) {
    //         if (response?.data?.message) setCount(0)
    //     }
    // }

    const openModal = () => {
        // markRead()
        setCount(0)
        document.body.classList.add('overflow-hidden')
        setIsModalOpen(!toggle)
        setToggle(!toggle)
    }

    useEffect(() => {
        getCount()
        const interval = setInterval(() => {
            getCount()
        }, 300000)
        return () => clearInterval(interval)
    }, [getCount])

    return (
        <div className="cursor-pointer">
            <span className="relative" onClick={() => openModal()}>
                <i className="icon-notification text-[25px] mobile:text-[20px]"></i>
                {!!count && (
                    <div className={styles.count}>
                        <span>{count}</span>
                    </div>
                )}
            </span>
        </div>
    )
}
export default Count
