import { useMemo } from 'react'
import kebabCase from 'lodash/kebabCase'
import { useFormikContext } from 'formik'
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import styles from '@/styles/Input.module.css'

const PhoneNumberField = ({
    id,
    name,
    label,
    noSpace = false,
    size,
    placeholder = 'Enter date',
    defaultValue = '',
}) => {
    const { handleBlur, getFieldMeta, setFieldValue } = useFormikContext() || {}

    const { value, error } = getFieldMeta ? getFieldMeta(name) : {}
    const id$ = useMemo(() => id || `${kebabCase(label)}`, [id, label])

    return (
        <div
            className={`${styles.inputModule} ${styles.DatePicker} custom-date-time custom-phone-number`}
        >
            <div
                className={`${styles.formGroup} ${
                    noSpace ? styles.noSpace : ''
                }`}
            >
                {label && (
                    <label
                        htmlFor={id$}
                        className="mb-2 block font-acumin-bold text-sm text-interface-900"
                    >
                        {label}
                    </label>
                )}
                <div
                    className={`relative ${styles.formControl} ${
                        error ? styles.hasError : ''
                    } ${styles[size]}`}
                >
                    <PhoneInput
                        id={id$}
                        name={name}
                        placeholder={placeholder}
                        value={value || defaultValue}
                        defaultCountry="US"
                        onChange={(eValue) => {
                            if (eValue) {
                                const numberLength = ('' + eValue).replace(
                                    /\D/g,
                                    ''
                                ).length

                                const phoneNumberObject =
                                    parsePhoneNumberFromString(eValue)

                                const country =
                                    phoneNumberObject?.countryCallingCode ===
                                    '1'
                                        ? 'US'
                                        : phoneNumberObject?.country

                                const number =
                                    country === 'US' && numberLength === 11
                                        ? eValue.substring(2)
                                        : eValue

                                eValue = new AsYouType(country).input(number)

                                eValue =
                                    country === 'US' && numberLength === 11
                                        ? '+1 ' + eValue
                                        : eValue
                            }
                            setFieldValue(name, eValue || '')
                        }}
                        onBlur={handleBlur}
                        international
                    />
                </div>
            </div>
        </div>
    )
}

export default PhoneNumberField
