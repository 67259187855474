import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setItem, setAlert, setListReset } from '@/redux/slices/sharedSlice'

import axios from '@/data/axios'

const useFetcher = () => {
    const selectedItem = useSelector((state) => state.shared.item)
    const alertMessage = useSelector((state) => state.shared.alertMessage)
    const resetList = useSelector((state) => state.shared.resetList)

    const dispatch = useDispatch()
    const fetchItem = useCallback(
        async (url) => {
            if (url) {
                const {
                    data: { data },
                } = await axios.get(url)
                dispatch(setItem(data))
            } else {
                dispatch(setItem(null))
            }
        },
        [dispatch]
    )

    const setSelectedItem = useCallback(
        (data) => {
            dispatch(setItem(data))
        },
        [dispatch]
    )

    const setAlertMessage = useCallback(
        (data) => {
            dispatch(setAlert(data))
        },
        [dispatch]
    )
    const setResetList = useCallback(
        (data) => {
            dispatch(setListReset(data))
        },
        [dispatch]
    )

    return {
        alertMessage,
        fetchItem,
        selectedItem,
        setSelectedItem,
        setAlertMessage,
        resetList,
        setResetList,
    }
}

export default useFetcher
