import React from 'react'
import styles from '@/styles/Alert.module.css'
const Alert = ({ variant, message, size, icon, iconStyle }) => {
    return (
        <div className={`${styles.alert} ${styles[variant]} ${styles[size]} `}>
            <span>
                {icon && <i className={`${`icon-` + icon} ${iconStyle}`}></i>}
                <span>{message}</span>
            </span>
        </div>
    )
}

export default Alert
