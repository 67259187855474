import jwtDecode from 'jwt-decode'
import { useMemo, useState, useEffect, useContext, createContext } from 'react'

import { useCookies } from 'react-cookie'

const SessionContext = createContext()

export const useSession = (options) => {
    const value = useContext(SessionContext)

    const { required, onAuthenticated, onUnauthenticated } = options ?? {}

    useEffect(() => {
        if (required === true && !value.isAuthenticated && onUnauthenticated) {
            onUnauthenticated()
        } else if (
            // required === false &&
            value.isAuthenticated &&
            onAuthenticated
        ) {
            onAuthenticated()
        }
    }, [required, value.isAuthenticated, onAuthenticated, onUnauthenticated])

    return value
}

export const SessionProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [nextURL, setNextURL, clearNextURL] = useCookies(['path'])

    const [authToken, setAuthToken, removeAuthToken] = useCookies([
        'accessToken',
    ])

    const [primaryClient, setPrimaryClient, removePrimaryClient] = useCookies([
        'clientDetails',
    ])

    let userScopes = []
    if (authToken.accessToken) {
        ;({ scopes: userScopes } = jwtDecode(authToken.accessToken))
    }
    const [scopes, setScopes] = useState(userScopes)

    const value = useMemo(
        () => ({
            user,
            setUser,
            nextURL,
            authToken,
            scopes,
            setScopes,
            setNextURL,
            clearNextURL,
            setAuthToken,
            removeAuthToken,
            isAuthenticated: !!authToken.accessToken,
            primaryClient,
            setPrimaryClient,
            removePrimaryClient,
        }),
        [
            user,
            nextURL,
            authToken,
            scopes,
            setNextURL,
            clearNextURL,
            setAuthToken,
            removeAuthToken,
            primaryClient,
            setPrimaryClient,
            removePrimaryClient,
        ]
    )

    useEffect(() => {
        if (authToken.accessToken) {
            const { scopes: newScopes } = jwtDecode(authToken.accessToken)

            setScopes(newScopes)
            const getUser = async () => {
                const getUserUrl = `${process.env.NEXT_PUBLIC_API_URL}/user/me`

                const fetchOptions = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken.accessToken}`,
                    },
                }

                const response = await fetch(getUserUrl, fetchOptions)

                if (response.ok) {
                    const data = await response.json()
                    setUser(data.data)
                    Intercom('trackEvent', 'last_activity')
                    Intercom('update', {
                        userId: data.data.id,
                        email: data.data.email,
                        name: data.data.firstName,
                    })
                } else {
                    if (response.status === 401) {
                        removeAuthToken('accessToken', {
                            path: '/',
                        })
                    }
                }
            }
            getUser()
        } else {
            setUser(null)
            setScopes([])
            Intercom('trackEvent', 'last_activity')
            Intercom('shutdown')
            Intercom('boot', intercomSettings)
        }
    }, [authToken, removeAuthToken])

    return (
        <SessionContext.Provider value={value}>
            {children}
        </SessionContext.Provider>
    )
}
