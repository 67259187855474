import dayjs from '@/data/dayjs'
import styles from '@/styles/Notification.module.css'

const Announcement = ({ notificationDetail, remove, read }) => {
    return (
        <li
            key={notificationDetail?.id}
            className={`${styles.item} ${
                notificationDetail?.isRead ? '' : styles.itemactive
            } ${'margin-left-right-none'}`}
        >
            <div
                onClick={() => read(notificationDetail?.id)}
                className="relative flex flex-col"
            >
                <span className={styles.image}>
                    <i className="icon-notification-bell"></i>
                </span>
                <div className="flex">
                    <h5 className={styles.heading}>Announcement</h5>
                    <span className={`${styles.time} ${'text-xs'}`}>
                        {dayjs(notificationDetail?.createdAt).fromNow()}
                    </span>
                </div>
                <span className={styles.description}>
                    <span>
                        <p
                            className="mb-0 text-sm"
                            dangerouslySetInnerHTML={{
                                __html: notificationDetail.data.message,
                            }}
                        ></p>
                    </span>
                    <div className={styles.btnClose}>
                        <span className="flex items-center justify-center p-1">
                            <i
                                onClick={() => remove(notificationDetail?.id)}
                                className="icon-close text-xs text-common-black"
                            ></i>
                        </span>
                    </div>
                </span>
            </div>
        </li>
    )
}

export default Announcement
