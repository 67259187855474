import React from 'react'
import Image from 'next/image'
import styles from '../styles/Error.module.css'

const Error = (props) => {
    return (
        <>
            <div className="fixed top-0 left-0 right-0 h-full w-full">
                <div className={styles.mapPosition}>
                    <div className="h-[500px] w-[500px]">
                        <Image
                            src="/map.svg"
                            alt={process.env.NEXT_PUBLIC_APP_NAME}
                            width={1000}
                            height={1000}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.errorPage}>
                    <div className={styles.inner}>
                        <div className="relative">
                            <span className={styles.aeroplance}>
                                <Image
                                    src="/aeroplane.svg"
                                    alt={process.env.NEXT_PUBLIC_APP_NAME}
                                    width={400}
                                    height={200}
                                    layout="responsive"
                                />
                            </span>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Error
