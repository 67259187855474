import Link from 'next/link'
import Image from 'next/image'

const Logo = ({
    src = '/logo.svg',
    width = 80,
    height = 76,
    link = '/',
    customClass,
}) => {
    return (
        <div className={`logo ${customClass}`}>
            <Link href={link}>
                <a>
                    <Image
                        src={src}
                        alt={process.env.NEXT_PUBLIC_APP_NAME}
                        layout="fixed"
                        width={width}
                        height={height}
                    />
                </a>
            </Link>
        </div>
    )
}

export default Logo
