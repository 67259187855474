import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'

import styles from '@/styles/Checkbox.module.css'

const SelectAllLegs = ({ flights }) => {
    const { setFieldValue, getFieldMeta } = useFormikContext()

    const { value: flightIds } = getFieldMeta('flightIds')

    const selectAll = useMemo(() => {
        return flights.length === flightIds.length
    }, [flights, flightIds])

    const handleChange = useCallback(
        (event) => {
            if (event.target.checked) {
                setFieldValue(
                    'flightIds',
                    flights.map((obj) => obj.id.toString())
                )
            } else {
                setFieldValue('flightIds', [])
            }
        },
        [flights, setFieldValue]
    )

    return (
        <div>
            <label htmlFor="selectAll" className={`${styles.checkBox}`}>
                <input
                    id="selectAll"
                    name="selectAll"
                    type="checkbox"
                    value={true}
                    checked={selectAll}
                    onChange={handleChange}
                />
                <span className={styles.checkmark}>
                    <i className="icon-check"></i>
                </span>
                <span className="text-common-black">Select All</span>
            </label>
        </div>
    )
}

export default SelectAllLegs
