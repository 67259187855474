import styles from '../styles/Auth.module.css'

import Link from 'next/link'

import Logo from '../components/Logo'

const Auth = (props) => {
    return (
        <main>
            <div className={styles.authMain}>
                <div
                    className={`${styles.logo} ${'auth-logo auth-logo-height'}`}
                >
                    <Logo width={80} height={76} link="/" />
                </div>
                <div className={`${styles.authLeft} scrollable h-screen`}>
                    <div className={`${styles.authContainer}`}>
                        <div className={styles.card}>
                            <div className={`${styles.logo} ${'auth-logo'}`}>
                                <Logo width={80} height={76} link="/" />
                            </div>
                        </div>
                        <div>
                            {props.heading && (
                                <h1 className="auth-heading">
                                    {props.heading}
                                </h1>
                            )}
                            {props.textDescription && (
                                <p
                                    className={`mr-1 inline-block text-sm ${styles.textDescription}`}
                                >
                                    {props.textDescription}
                                </p>
                            )}
                            {props.linkDescription && (
                                <Link
                                    href={props.link}
                                    className={styles.linkDescription}
                                >
                                    <a>{props.linkDescription}</a>
                                </Link>
                            )}
                            <div className="auth-left-side pt-10">
                                {props.children}
                            </div>
                        </div>
                        <div>
                            <p className={styles.authFooter}>
                                Copyright {new Date().getFullYear()}{' '}
                                {process.env.NEXT_PUBLIC_APP_NAME}. All Rights
                                Reserved.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.authRight}
                    style={{
                        backgroundImage: `url("/auth-image.jpg")`,
                    }}
                ></div>
            </div>
        </main>
    )
}

export default Auth
