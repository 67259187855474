import * as Yup from 'yup'
import { changePassword } from './messages'

export default Yup.object().shape({
    oldPassword: Yup.string()
        .required(changePassword.oldPassword.required)
        .min(8, changePassword.newPassword.min)
        .max(50, changePassword.newPassword.max),

    newPassword: Yup.string()
        .required(changePassword.newPassword.required)
        .min(8, changePassword.newPassword.min)
        .max(50, changePassword.newPassword.max),

    confirmPassword: Yup.string()
        .required(changePassword.confirmPassword.required)
        .min(8, changePassword.confirmPassword.min)
        .max(50, changePassword.confirmPassword.max)
        .oneOf(
            [Yup.ref('newPassword'), null],
            changePassword.newPassword.mismatch
        )
        .when(['currentPassword', 'newPassword'], {
            is: (currentPassword, newPassword) =>
                currentPassword?.length && newPassword?.length,
            then: Yup.string().required(
                changePassword.confirmPassword.required
            ),
            otherwise: Yup.string().nullable(),
        }),
})
