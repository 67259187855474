import { useFormikContext } from 'formik'
import styles from '../../styles/Checkbox.module.css'

const Checkbox = ({
    id,
    name,
    label,
    defaultValue = false,
    disabled = false,
    style2 = false,
}) => {
    const { handleChange, getFieldMeta } = useFormikContext() || {}
    const { value } = getFieldMeta ? getFieldMeta(name) : {}

    return (
        <div>
            {!style2 && (
                <>
                    <label
                        htmlFor={id}
                        className={`${styles.checkBox} ${
                            disabled ? styles.disabled : ''
                        }`}
                    >
                        <input
                            id={id}
                            name={name}
                            type="checkbox"
                            onChange={handleChange}
                            value={defaultValue}
                            checked={value}
                            disabled={disabled}
                        />
                        <span className={styles.checkmark}>
                            <i className="icon-check"></i>
                        </span>
                        <span className="text-common-black">{label}</span>
                    </label>
                </>
            )}
            {style2 && (
                <>
                    <label
                        htmlFor={id}
                        className={`${styles.checkBox} ${
                            disabled ? styles.disabled : ''
                        }`}
                    >
                        <span className="mr-3 text-common-black">{label}</span>
                        <input
                            id={id}
                            name={name}
                            type="checkbox"
                            onChange={handleChange}
                            value={defaultValue}
                            checked={value}
                            disabled={disabled}
                        />
                        <span className={styles.checkmark}>
                            <i className="icon-check"></i>
                        </span>
                    </label>
                </>
            )}
        </div>
    )
}

export default Checkbox
