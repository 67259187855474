import * as Yup from 'yup'
import { login } from './messages'

export default Yup.object().shape({
    email: Yup.string()
        .email(login.email.invalid)
        .required(login.email.required),

    password: Yup.string()
        .trim()
        .required(login.password.required)
        .min(8, login.password.min),
})
