import Head from 'next/head'
import Link from 'next/link'
import { Formik, Form } from 'formik'
import { useRouter } from 'next/router'
import { useMemo, useCallback, useState } from 'react'

import Alert from '@/components/base/Alert'
import Input from '@/components/base/TextBox'
import Button from '@/components/base/Button'
import Checkbox from '@/components/base/Checkbox'
import SeparatorWithText from '@/components/base/SeparatorWithText'

import { useSession } from '@/contexts/Session'
import loginValidation from '@/validations/login'

import axios from '@/data/axios'

const AuthLogin = () => {
    const [loading, setLoading] = useState(false)
    const { nextURL, setAuthToken, setPrimaryClient } = useSession()
    const { push } = useRouter()

    const initialState = useMemo(
        () => ({
            email: '',
            password: '',
            remember: false,
        }),
        []
    )

    const handleSubmit = useCallback(
        async ({ email, password, remember }, { setErrors, setSubmitting }) => {
            setSubmitting(false)
            setLoading(true)

            const signInUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/login`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                    remember,
                    permission: 'client-login',
                }),
            }

            const response = await fetch(signInUrl, fetchOptions)

            const data = await response.json()

            if (response.ok) {
                setAuthToken('accessToken', data.data.accessToken, {
                    path: '/',
                })

                setAuthToken('refreshToken', data.data.refreshToken, {
                    path: '/',
                })

                const userData = await axios.get('user/me')

                if (
                    userData?.data?.data &&
                    userData?.data?.data?.roleName !== 'Client'
                ) {
                    setPrimaryClient(
                        'clientDetails',
                        JSON.stringify({
                            firstName: userData?.data?.data?.clientFirstName,
                            lastName: userData?.data?.data?.clientLastName,
                            clientId: userData?.data?.data?.clientId,
                        })
                    )
                }
            } else {
                setErrors({
                    email: data.message,
                    password: data.message,
                })
            }

            setLoading(false)
        },
        [setAuthToken, setPrimaryClient]
    )

    return (
        <>
            <Head>
                <title>Sign in | {process.env.NEXT_PUBLIC_APP_NAME}</title>
            </Head>
            <div className="w-full">
                <h2 className="auth-heading mb-6">Sign In To Your Account</h2>
            </div>
            <Formik
                initialValues={initialState}
                validationSchema={loginValidation}
                onSubmit={handleSubmit}
            >
                {({ errors, touched }) => {
                    const firstKey = Object.keys(errors)[0]
                    const showError = !!errors[firstKey] && !!touched[firstKey]

                    return (
                        <Form noValidate className="w-full">
                            <div>
                                <div>
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        label="Email address"
                                        placeholder="Enter your email address"
                                    />
                                </div>
                                <div>
                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        label="Password"
                                        placeholder="Enter your account password"
                                    />
                                </div>
                            </div>
                            <div className="mb-5 grid grid-cols-2">
                                <div className="grid grid-cols-1">
                                    <Checkbox
                                        id="remember"
                                        name="remember"
                                        label="Remember me"
                                    />
                                </div>
                                <div className="relative top-0.5 ml-auto text-right">
                                    <Link href="/password/request">
                                        <a className="font-acumin font-normal text-primary-500">
                                            Forgot password?
                                            <span className="custom-underline"></span>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                {showError && (
                                    <Alert
                                        variant="danger"
                                        message={errors[firstKey]}
                                        icon="close"
                                        iconStyle="text-danger-500 pr-2 text-xs"
                                    ></Alert>
                                )}
                            </div>
                            <div>
                                <Button
                                    text="SIGN IN"
                                    type="submit"
                                    variant="primary"
                                    modification="w-full"
                                    id="signin_submit"
                                    loading={loading}
                                />
                            </div>
                            {!nextURL.path?.endsWith('review-contract') && (
                                <>
                                    <div className="mt-6 mb-6">
                                        <SeparatorWithText label="NOT A MEMBER?" />
                                    </div>
                                    <div>
                                        <Button
                                            text="CREATE AN ACCOUNT"
                                            type="button"
                                            variant="outline-black"
                                            modification="w-full"
                                            id="signin_submit"
                                            click={() => push('/register/')}
                                            disabled={loading}
                                        />
                                    </div>
                                </>
                            )}
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

AuthLogin.guest = true

export default AuthLogin
