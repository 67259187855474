import Link from 'next/link'
import dayjs from '@/data/dayjs'
// import commaNumber from 'comma-number'
import styles from '@/styles/Notification.module.css'

const Booking = ({ notificationDetail, remove, read }) => {
    const data = notificationDetail?.data ? notificationDetail.data : {}
    const notificationMessages = {
        'booking-price-quote-approved': {
            message: `<b>You</b> have approved the price quote (q${data?.booking?.id}) waiting for contract.`,
            link: `/my-trip/${notificationDetail?.bookingId}/review-quotes`,
        },
        'booking-price-quote-recevied': {
            message: `Click here to view and select an aircraft option.`,
            link: `/my-trip/${notificationDetail?.bookingId}/review-quotes`,
            heading: 'Your quotes are ready for review',
        },
        'flight-quote-request-received': {
            message: `New flight quote request received on booking id ${notificationDetail?.bookingId}.`,
            link: `/my-trip/${notificationDetail?.bookingId}/review-quotes`,
            heading: 'Flight quotes received',
        },
        'booking-canceled': {
            message: `Your booking id <b>${notificationDetail?.bookingId}</b> has been cancelled.`,
            link: `/my-trip/${notificationDetail?.bookingId}/flight-details`,
            heading: 'Booking Canceled',
        },
        'booking-confirmed': {
            message: `Your flight on ${dayjs(
                notificationDetail?.data?.data?.bookingFlight?.[0]
                    ?.takeOffDate || ''
            ).format('M/DD/YY')} to ${
                notificationDetail?.data?.data?.bookingFlight?.[0]
                    ?.destinationCode || ''
            } is confirmed and on our schedule.`,
            link: `/my-trip/${notificationDetail?.bookingId}/flight-details`,
            heading: 'Flight Confirmed',
        },
        'flight-additional-quotes-received': {
            message: `Click here to view and approve this request.`,
            link: `/my-trip/${notificationDetail?.bookingId}/flight-details`,
            heading: 'A quote for your additional request is ready for review',
        },
        'contract-ready-for-sign': {
            message: `Click here to sign and book your flight.`,
            link: `/my-trip/${notificationDetail?.bookingId}/payment`,
            heading: 'Your contract is ready for review',
        },
        'payment-hold': {
            message: `Your payment against booking id ${notificationDetail?.bookingId} has been hold.`,
            link: `/my-trip/${notificationDetail?.bookingId}/payment`,
            heading: 'Payment',
        },
        'payment-charged': {
            message: `Your payment against booking id <b>${notificationDetail?.bookingId}</b> has been charged.`,
            link: `/my-trip/${notificationDetail?.bookingId}/payment`,
            heading: 'Payment',
        },
        'payment-added': {
            message: `Payment added for booking id <b>${notificationDetail.bookingId}</b>.`,
            link: `/my-trip/${notificationDetail?.bookingId}/payment`,
        },
        'payment-released': {
            message: `Payment released for booking id <b>${notificationDetail.bookingId}</b>.`,
            link: `/my-trip/${notificationDetail?.bookingId}/payment`,
        },
        // 'payment-refunded': {
        //     message: `$${commaNumber(
        //         data.amount
        //     )} has been refunded for booking id <b>${
        //         notificationDetail.bookingId
        //     }</b> by <b>${data?.sender?.firstName} ${
        //         data?.sender?.lastName
        //     }</b>.`,
        //     link: `/my-trip/${notificationDetail?.bookingId}/flight-details`,
        //     heading: 'Refund payment',
        // },
        'flight-status-alert': {
            message: `${data?.message}`,
            link: `/my-trip/${notificationDetail?.bookingId}/flight-details`,
            heading: `Flight Status Alert (${data?.flight?.originCode}->${data?.flight?.destinationCode})`,
        },
        'booking-archived': {
            message: `Booking id <b>${notificationDetail.bookingId}</b> has been archived.`,
            link: `/my-trip/${notificationDetail?.bookingId}/flight-details`,
            heading: 'Booking Archived',
        },
        'update-payment-method': {
            message: `The contract for <b>${
                data?.sender?.lastName
            }'s</b> flight to <b>${
                data?.lastDestinationCode || 'N/A'
            }</b> has been signed.`,
            link: `/my-trip/${notificationDetail.bookingId}/payment`,
            heading: `Your contract has been signed`,
        },
    }

    const message = notificationMessages[notificationDetail.feature]?.message
    const link = notificationMessages[notificationDetail.feature]?.link
    const heading = notificationMessages[notificationDetail.feature]?.heading

    return (
        <li
            key={notificationDetail?.id}
            className={`${styles.item} ${
                notificationDetail?.isRead ? '' : styles.itemactive
            } ${'margin-left-right-none'}`}
        >
            <div
                onClick={() => read(notificationDetail?.id)}
                className="relative flex flex-col"
            >
                {link && (
                    <Link href={link}>
                        <a className="absolute h-full w-full"></a>
                    </Link>
                )}
                <span className={styles.image}>
                    <i className="icon-notification-bell"></i>
                </span>
                <div className="flex">
                    <h5 className={styles.heading}>{heading}</h5>
                    <span className={`${styles.time} ${'text-xs'}`}>
                        {dayjs(notificationDetail?.createdAt).fromNow()}
                    </span>
                </div>
                <span className={styles.description}>
                    <span>
                        <p
                            className="mb-0 text-sm"
                            dangerouslySetInnerHTML={{
                                __html: message,
                            }}
                        ></p>
                    </span>
                    <div className={styles.btnClose}>
                        <span className="flex items-center justify-center p-1">
                            <i
                                onClick={() => remove(notificationDetail?.id)}
                                className="icon-close text-xs text-common-black"
                            ></i>
                        </span>
                    </div>
                </span>
            </div>
        </li>
    )
}

export default Booking
