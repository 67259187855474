/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import keys from 'lodash/keys'

import { Formik, Form } from 'formik'
import React, { useState, useMemo, useCallback } from 'react'

import TextBox from '@/components/base/TextBox'
import Button from '@/components/base/Button'
import Alert from '@/components/base/Alert'
import LegListItem from '@/components/LegsListItem'
import AllLegsCheckBox from '@/components/SelectAllLegs'

import flightValidation from '../../validations/searchFlight'
import subscribeValidation from '../../validations/subscribe'

import axios from '@/data/axios'

import useFetcher from '@/redux/hooks/useFetcher'

const AddExistingTrip = ({ setOpenModal, flightData, bookingId }) => {
    const { setResetList } = useFetcher()
    const [loading, setLoading] = useState(false)
    const [flights, setFlightDetails] = useState(flightData || [])
    const [tripId, setTripId] = useState(bookingId || '')
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [isShowLeg, setShowLeg] = useState(false)

    const initialState = useMemo(
        () => ({
            bookingId: tripId || '',
            clientName: '',
            flightIds: flights
                .filter((flight) => flight.flightSubscribers.length)
                .map((flight) => flight.id.toString()),
        }),
        [flights, tripId]
    )

    const getFlights = useCallback(async (formData) => {
        setLoading(true)
        try {
            const { data } = await axios.get(`flight/subscribe-list`, {
                params: {
                    bookingId: formData?.bookingId,
                    clientName: formData?.clientName,
                },
            })

            if (data?.data) {
                if (data?.data?.length) {
                    setFlightDetails(data?.data)
                    setTripId(formData?.bookingId)
                } else {
                    setErrorMessage(`This flight does not exist.`)
                }
            }
        } catch ({ response }) {
            if (response?.data?.message) {
                setErrorMessage(response.data.message)
            }
        }
        setLoading(false)
    }, [])

    const subscribe = useCallback(
        async (formData) => {
            setLoading(true)
            try {
                const { data } = await axios.post(`subscriber/me`, {
                    flightIds: formData?.flightIds,
                    bookingId: flights?.[0]?.bookingId,
                })

                if (data) {
                    const { message } = data
                    setErrorMessage(null)
                    setSuccessMessage(message)

                    const timer = setTimeout(() => {
                        setSuccessMessage(null)
                        setResetList(true)
                        setOpenModal(false)
                    }, 2000)
                    return () => clearTimeout(timer)
                }
            } catch ({ response }) {
                if (response?.data?.message) {
                    setErrorMessage(response.data.message)
                    setLoading(false)
                }
            }
        },
        [flights, setOpenModal, setResetList]
    )

    return (
        <>
            <div className="overflow-visible">
                <Formik
                    onSubmit={flights.length ? subscribe : getFlights}
                    initialValues={initialState}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    validationSchema={
                        flights.length ? subscribeValidation : flightValidation
                    }
                >
                    {({ errors, touched, values, resetForm }) => {
                        const firstKey = keys(errors)[0]
                        const showError =
                            (!!errors[firstKey] && !!touched[firstKey]) ||
                            errorMessage
                        const message = errors[firstKey] || errorMessage

                        return (
                            <>
                                <Form noValidate>
                                    <div className="scrollable -mr-[23px] pr-5 mobile:max-h-screen">
                                        <div>
                                            <div>
                                                {!flights?.length && (
                                                    <div>
                                                        <div>
                                                            <TextBox
                                                                id="bookingId"
                                                                name="bookingId"
                                                                type="text"
                                                                label="Trip Number"
                                                                placeholder="Enter trip number"
                                                            />
                                                        </div>
                                                        <div>
                                                            <TextBox
                                                                id="clientName"
                                                                name="clientName"
                                                                type="text"
                                                                label="Last Name"
                                                                placeholder="Enter last name"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {!!flights?.length && (
                                                    <div className="relative mt-4">
                                                        <div className=" flex w-full justify-between pr-[17px]">
                                                            <h6 className="text-sm ">
                                                                Select Legs
                                                            </h6>
                                                            <div>
                                                                <div className="mr-4">
                                                                    <AllLegsCheckBox
                                                                        flights={
                                                                            flights
                                                                        }
                                                                    ></AllLegsCheckBox>
                                                                </div>
                                                                <div
                                                                    className={`absolute right-0 top-2 mobile:-top-[9px] ${
                                                                        !isShowLeg
                                                                            ? 'accordian-active'
                                                                            : ''
                                                                    }`}
                                                                    onClick={() =>
                                                                        setShowLeg(
                                                                            !isShowLeg
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="z-2 cursor-pointer font-acumin-bold text-base text-primary-500">
                                                                        <i className="icon-caret-down relative bottom-2"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {!isShowLeg && (
                                                            <div>
                                                                {!!flights?.length && (
                                                                    <div className="mb-4">
                                                                        <ul className="item-table mb-0 rounded">
                                                                            {flights?.map(
                                                                                (
                                                                                    flight
                                                                                ) => (
                                                                                    <LegListItem
                                                                                        key={
                                                                                            flight?.id
                                                                                        }
                                                                                        flight={
                                                                                            flight
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        {showError && (
                                            <Alert
                                                variant="danger"
                                                message={message}
                                                icon="warning"
                                                iconStyle="text-danger-500 pr-2 text-xs"
                                            ></Alert>
                                        )}

                                        {successMessage && (
                                            <Alert
                                                variant="success"
                                                message={successMessage}
                                                icon="check"
                                                iconStyle="text-success-500 pr-2 text-xs"
                                            ></Alert>
                                        )}
                                    </div>

                                    <div className="mt-6 ml-auto w-full overflow-visible">
                                        <div>
                                            <Button
                                                text={
                                                    !flights?.length
                                                        ? 'Search Trip'
                                                        : 'Update'
                                                }
                                                type="submmit"
                                                variant="primary"
                                                modification="w-full"
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )
                    }}
                </Formik>
            </div>
        </>
    )
}

export default AddExistingTrip
