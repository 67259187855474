import Header from '@/components/base/Header'
import Footer from '@/components/base/Footer'
const Main = (props) => {
    return (
        <main className="relative">
            <Header />
            <div className="flex">
                <div className="w-900 m-auto mb-6 py-8 ipad:w-full ipad:px-[30px]">
                    {props.children}
                </div>
            </div>
            <Footer />
        </main>
    )
}

export default Main
